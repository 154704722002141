import * as React from 'react';
import { SvgIcon, SvgIconProps } from "@material-ui/core";


const Icon: React.FunctionComponent<SvgIconProps> = (props) => {

    return (
    <SvgIcon {...props} focusable="false" viewBox="0 0 18 18" aria-hidden="true">
        <defs>
            <radialGradient id="a" cx="9" cy="7.35" r="6.42" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#b77af4"/>
                <stop offset=".21" stopColor="#b378f2"/>
                <stop offset=".43" stopColor="#a672ed"/>
                <stop offset=".65" stopColor="#9267e4"/>
                <stop offset=".88" stopColor="#7559d8"/>
                <stop offset="1" stopColor="#624fd0"/>
            </radialGradient>
            <linearGradient id="b" x1="9.02" y1="3.91" x2="9.08" y2="11.49" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#f2f2f2"/>
                <stop offset=".23" stopColor="#f1f1f2" stopOpacity=".99"/>
                <stop offset=".37" stopColor="#ededf1" stopOpacity=".95"/>
                <stop offset=".48" stopColor="#e7e5f0" stopOpacity=".89"/>
                <stop offset=".58" stopColor="#dedbee" stopOpacity=".81"/>
                <stop offset=".67" stopColor="#d3ceeb" stopOpacity=".7"/>
                <stop offset=".76" stopColor="#c4bee8" stopOpacity=".57"/>
                <stop offset=".84" stopColor="#b4abe5" stopOpacity=".41"/>
                <stop offset=".92" stopColor="#a095e1" stopOpacity=".22"/>
                <stop offset=".99" stopColor="#8b7ddc" stopOpacity=".02"/>
                <stop offset="1" stopColor="#897bdc" stopOpacity="0"/>
            </linearGradient>
        </defs>
        <path d="M10.23 17.39l.81-.87V14.2H7v2.32l.81.87a.32.32 0 00.19.11h2a.32.32 0 00.23-.11z" fill="#cecece"/>
        <path d="M9 .5a5.89 5.89 0 00-5.91 6.57c.27 2.47 2.62 3.62 3.29 6.75a.49.49 0 00.47.38h4.3a.49.49 0 00.47-.38c.67-3.13 3-4.28 3.29-6.75A5.89 5.89 0 009 .5zM7 14.2" fill="url(#a)"/>
        <path d="M11.46 3.79a1.4 1.4 0 00-1.35 1.44V6H8v-.77a1.41 1.41 0 00-1.41-1.44 1.4 1.4 0 00-1.35 1.44 1.41 1.41 0 001.35 1.45h.64v6a.36.36 0 00.72 0v-6h2.16v6a.36.36 0 10.72 0v-6h.63a1.4 1.4 0 001.35-1.45 1.4 1.4 0 00-1.35-1.44zM7.23 6h-.68a.74.74 0 01-.68-.77.74.74 0 01.68-.77.74.74 0 01.68.77zm4.28 0h-.68v-.81a.68.68 0 111.35 0 .73.73 0 01-.67.81z" fill="url(#b)"/>
        <path fill="#999" d="M6.96 15.8l4.08-.79v-.45l-4.08.8v.44zM11.04 16.11v-.44l-4.08.81v.04l.31.34 3.77-.75z"/>

    </SvgIcon>
    );
};

export default Icon;
