import React from 'react';
import {
    HomePageToolkit,
    HomePageStarredEntities,
    HeaderWorldClock,
    ClockConfig,
    WelcomeTitle,

} from '@backstage/plugin-home';
import { Content, Page, InfoCard, Header } from '@backstage/core-components';
import {
  SearchContextProvider,
} from '@backstage/plugin-search-react';
import { HomePageSearchBar, } from '@backstage/plugin-search';
import { Grid, makeStyles } from '@material-ui/core';
import UrlsIcon from '@material-ui/icons/Link';
import AzureDevopsIcon from './Icons/AzureDevopsIcon';
import LucidIcon from './Icons/LucidIcon';
import MicrosoftIcon from './Icons/MicrosoftIcon';
import ConsulIcon from './Icons/ConsulIcon';
import SlackIcon from './Icons/SlackIcon';
import TeamsIcon from './Icons/TeamsIcon';
import GvIcon from './Icons/GvIcon';
import MonitorIcon from '@material-ui/icons/TrafficOutlined';
import MendIcon from '@material-ui/icons/BugReport';
import FigmaIcon from './Icons/FigmaIcon';
import GrafanaIcon from './Icons/GrafanaIcon';
import AzureDataExplorer from './Icons/AzureDataExplorer';
import ApplicationInsights from './Icons/ApplicationInsights';



const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none'
  }
}));

const customLinks: { url: string, label: string, icon: React.JSX.Element }[] = [
    {
        url: 'https://dev.azure.com/esmartsystems/eSmart%20Systems/_wiki/wikis/eSmart%20Systems.wiki/3395/gridvision.com-URL-mappings',
        label: 'GV Url Mappings',
        icon: <UrlsIcon />,
    },
    {
        url: 'https://portal.azure.com/',
        label: 'Azure Portal',
        icon: <MicrosoftIcon />,
    },
    {
        url: 'https://esmart-gv.slack.com/',
        label: 'Slack',
        icon: <SlackIcon />,
    },
    {
        url: 'https://teams.microsoft.com/',
        label: 'Teams',
        icon: <TeamsIcon />,
    },
    {
        url: 'https://saas-eu.mend.io/',
        label: 'Mend',
        icon: <MendIcon />,
    }
];

const monitorLogLinks: { url: string, label: string, icon: React.JSX.Element }[] = [
  {
    url: 'https://grid-vision-monitor.azurewebsites.net/',
    label: 'GV Monitor',
    icon: <MonitorIcon />,
  },
  {
    url: 'https://dataexplorer.azure.com/dashboards/9c5c57e4-d021-42dc-a761-92737bba0df9?p-_participant=all&p-Tenant=dev-we&p-_projectId=all#c87fdf9d-79de-4de9-8fef-26c0876ffc1b',
    label: 'Azure Data Explorer',
    icon: <AzureDataExplorer />,
  },
  {
    url: 'https://dev.azure.com/esmartsystems/eSmart%20Systems/_wiki/wikis/eSmart%20Systems.wiki/2917/Grid-Vision-Cli-Tool',
    label: 'GV Cli Tool',
    icon: <AzureDevopsIcon />,
  },
  {
    url: 'https://grafana.monitoring.dev-we.cluster.esmartapi.com/',
    label: 'Grafana Dev',
    icon: <GrafanaIcon />,
  },
  {
    url: 'https://grafana.monitoring.prod-we.cluster.esmartapi.com/',
    label: 'Grafana EU Prod',
    icon: <GrafanaIcon />,
  },
  {
    url: 'https://grafana.monitoring.prod-eastus.cluster.esmartapi.com/',
    label: 'Grafanaa US Stage and Prod',
    icon: <GrafanaIcon />,
  },
  {
    url: 'https://grafana.monitoring.dev-common.cluster.esmartapi.com/',
    label: 'Grafana Common Dev',
    icon: <GrafanaIcon />,
  },
  {
    url: 'https://grafana.monitoring.prod-common.cluster.esmartapi.com/',
    label: 'Grafana Common Prod',
    icon: <GrafanaIcon />,
  },
  {
    url: 'https://portal.azure.com/#browse/microsoft.insights%2Fcomponents',
    label: 'Application Insights',
    icon: <ApplicationInsights />,
  }


];

const apiLinks: { url: string, label: string, icon: React.JSX.Element }[] = [
  {
    url: 'https://client.gv-apiexplorer.prod-we.stamp.esmartapi.com/',
    label: 'WE Prod',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.gv.apiexplorer.prod-eastus.stamp.esmartapi.com/',
    label: 'US Prod',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.gv-apiexplorer.prod-common.stamp.esmartapi.com/',
    label: 'Common Prod',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.gv-apiexplorer.stage-we.stamp.esmartapi.com/',
    label: 'WE Stage',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.gv-apiexplorer.stage-eastus.stamp.esmartapi.com/',
    label: 'US Stage',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.gv-apiexplorer.stage-common.stamp.esmartapi.com/',
    label: 'Common Stage',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.gv-apiexplorer.dev-we.stamp.esmartapi.com/',
    label: 'WE Dev',
    icon: <GvIcon />,
  },
  {
    url: 'https://client.gv-apiexplorer.dev-common.stamp.esmartapi.com/',
    label: 'Common Dev',
    icon: <GvIcon />,
  },

];

const appLinks: { url: string, label: string, icon: React.JSX.Element }[] = [
  {
    url: 'https://preview.gridvision.com',
    label: 'Grid Vision [Prod]',
    icon: <GvIcon />,
  },
  {
    url: 'https://stage-preview.gridvision.com',
    label: 'Grid Vision [Stage]',
    icon: <GvIcon />,
  },
  {
    url: 'https://dev-preview.gridvision.com',
    label: 'Grid Vision [Dev]',
    icon: <GvIcon />,
  },
  {
    url: 'https://app.gridvision.com',
    label: 'Inspect [Prod]',
    icon: <GvIcon />,
  },
  {
    url: 'https://stage.gridvision.com',
    label: 'Inspect [Stage]',
    icon: <GvIcon />,
  },
  {
    url: 'https://dev.gridvision.com',
    label: 'Inspect [Dev]',
    icon: <GvIcon />,
  },

];

const devLinks: { url: string, label: string, icon: React.JSX.Element }[] = [
  {
    url: 'https://dev.azure.com/esmartsystems/eSmart%20Systems/_wiki/wikis/eSmart%20Systems.wiki/18/Developer-Handbook',
    label: 'Wiki',
    icon: <AzureDevopsIcon />,
  },
  {
    url: 'https://dev.azure.com/esmartsystems/eSmart%20Systems/_sprints/taskboard/GV%20Dev%20team/eSmart%20Systems',
    label: 'Dev Team Sprint',
    icon: <AzureDevopsIcon />,
  },
  {
    url: 'https://dev.azure.com/esmartsystems/eSmart%20Systems/_backlogs/backlog/Grid%20Vision/Portifolio?showParents=false',
    label: 'GV Backlog',
    icon: <AzureDevopsIcon />,
  },
  {
    url: 'https://lucid.app/documents#/documents?folder_id=project',
    label: 'Lucid',
    icon: <LucidIcon />,
  },
  {
    url: 'https://figma.com',
    label: 'Figma',
    icon: <FigmaIcon />,
  },
  {
    url: 'https://consul.cluster.prod.common.esap.esmartapi.com/',
    label: 'Consul',
    icon: <ConsulIcon />,
  }

];

const clockConfigs: ClockConfig[] = [
  {
    label: 'UTC',
    timeZone: 'UTC'
  },
  {
    label: 'OSL',
    timeZone: 'Europe/Oslo'
  },
  {
    label: 'NYC',
    timeZone: 'America/New_York'
  },
  {
    label: 'LA',
    timeZone: 'America/Los_Angeles'
  },
  {
    label: 'Sao Paulo',
    timeZone: 'America/Sao_Paulo'
  }];
const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
};

const languages = ['English'];

export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid container item xs={12} justifyContent='center'>
              <Header title={< WelcomeTitle language={languages} />} pageTitleOverride="Home">
                <HeaderWorldClock clockConfigs={clockConfigs} customTimeFormat={timeFormat} />
              </Header>
              <HomePageSearchBar
                InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline }}}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={8}>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title="Monitoring and logging"
                  tools={monitorLogLinks}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title="API Explorer"
                  tools={apiLinks}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title="Grid Vision Clients"
                  tools={appLinks}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title="Developer Tools"
                  tools={devLinks}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title="Useful Links"
                  tools={customLinks}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InfoCard title="Information">
                  {/* placeholder for content */}
                    <div style={{ height: 370 }}>
                                      What will you find here? <br />
                                      <ul>
                                          <li>A catalog of all systems and components used to build Grid Vision</li>
                                          <li>CI/CD pipelines</li>
                                          <li>Pull requests</li>
                                          <li>Tech Documentation</li>
                                          <li>Component dependencies</li>
                                          <li>Available API's</li>
                    </ul>
                    Please see the <u><a href="https://dev.azure.com/esmartsystems/eSmart%20Systems/_wiki/wikis/eSmart%20Systems.wiki/18/Developer-Handbook">Developer Handbook in our Wiki</a></u> and make sure all your work is documented in the right place.

                    </div>
                </InfoCard>
              </Grid>
            </Grid>
            <Grid container item xs={4}>
              <Grid item xs={12} md={12}>
                <HomePageStarredEntities />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};